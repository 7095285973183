import React, { Component } from "react"
import { graphql } from "gatsby"
import "queries/featuredTemplateQuery"
import Layout from "components/Layout/Layout"
import FeaturedLayout from "components/FeaturedLayout/FeaturedLayout"

class FeaturedTemplate extends Component {
    render() {
        const data = this.props.data.wpPage
        const title = data.seo.title || data.title
        const googleAds = data.acfGoogleAds
        const googleAdsSidebar = data.template.acfGoogleAdsSidebar
        return (
            <Layout title={title} description={data.seo.metaDesc} googleAds={googleAds}>
                <FeaturedLayout data={data} googleAdsSidebar={googleAdsSidebar} />
            </Layout>
        )
    }
}

export const pageQuery = graphql`
query($id: String!) {
    wpPage(id: {eq: $id}) {
        title
        uri
        seo {
            title
            metaDesc
        }
        acfGoogleAds {
            bellybandName
        }
        template {
            ... on WpTemplate_FeatureArticle {
                acfGoogleAdsSidebar {
                    sidebarMpus {
                        type
                        name
                    }
                }
                ...featuredTemplateQuery
            }
        }
    }
}
`

export default FeaturedTemplate